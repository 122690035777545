<template>
  <div class="outside text-center">
    <!-- <div class="subtitle_">
      上場企業の<span class="text-primary"><b>イケメン彼氏</b></span>が欲しい？
    </div> -->
    <div class="subtitle_">
      上場企業をご希望ですか？
    </div>
    <div class="title_">
      主要取引先
      <b-avatar
        size="55"
        src="https://firebasestorage.googleapis.com/v0/b/collect-staging-env.appspot.com/o/brand%2Fgirl-blue-falling.png?alt=media&token=7a0246c4-22d3-4037-a204-fd6e7c29b5d8"
        variant="light-primary"
      />
    </div>
    <div class="subtitle_">
      転職コレなら、あなたも有名企業で働けます！
    </div>
    <div class="d-flex mt-2">
      <div class="">
        <b-img
          v-for="(item, index) in picList"
          :key="index"
          :src="item"
          class="imgSpace"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  // BRow, BCol,
  BImg, BAvatar,
} from 'bootstrap-vue'

export default {
  components: {
    // BRow,
    // BCol,
    BImg,
    BAvatar,
  },
  data() {
    return {
      picList: [
        'https://firebasestorage.googleapis.com/v0/b/collect-staging-env.appspot.com/o/logos%2Fsuppliers-img01.jpg?alt=media&token=10340dcf-dd6f-428c-a467-0b4bc7bdbe95',
        'https://firebasestorage.googleapis.com/v0/b/collect-staging-env.appspot.com/o/logos%2Fsuppliers-img02.jpg?alt=media&token=c016af06-6160-4c49-934c-4e620643ed75',
        'https://firebasestorage.googleapis.com/v0/b/collect-staging-env.appspot.com/o/logos%2Fsuppliers-img03.jpg?alt=media&token=e5186249-ca02-4f94-a118-dc38a8b7bbce',
        'https://firebasestorage.googleapis.com/v0/b/collect-staging-env.appspot.com/o/logos%2Fsuppliers-img04.jpg?alt=media&token=3a6ca6cd-77ff-41c0-b620-9ba2a3c6be55',
        'https://firebasestorage.googleapis.com/v0/b/collect-staging-env.appspot.com/o/logos%2Fsuppliers-img05.jpg?alt=media&token=81909712-be89-4a3d-b333-6b0908ff5cda',
        'https://firebasestorage.googleapis.com/v0/b/collect-staging-env.appspot.com/o/logos%2Fsuppliers-img06.jpg?alt=media&token=d4a4e099-47c1-4b4a-ac4f-74c91f23616d',
        'https://firebasestorage.googleapis.com/v0/b/collect-staging-env.appspot.com/o/logos%2Fsuppliers-img07.jpg?alt=media&token=9fe1934e-b04b-45d5-b3df-a9cc458c878d',
        'https://firebasestorage.googleapis.com/v0/b/collect-staging-env.appspot.com/o/logos%2Fsuppliers-img08.jpg?alt=media&token=c13117e5-55a1-431b-965f-a91c1009d48b',
        'https://firebasestorage.googleapis.com/v0/b/collect-staging-env.appspot.com/o/logos%2Fsuppliers-img09.jpg?alt=media&token=376d2456-668b-483b-86f5-075c02798b54',
        'https://firebasestorage.googleapis.com/v0/b/collect-staging-env.appspot.com/o/logos%2Fsuppliers-img10.jpg?alt=media&token=3cd1cca5-c90e-4a19-b09a-e124626cdacc',
        'https://firebasestorage.googleapis.com/v0/b/collect-staging-env.appspot.com/o/logos%2Fsuppliers-img11.jpg?alt=media&token=dd1691ea-7590-4dd4-a54d-86ee807d75d6',
        'https://firebasestorage.googleapis.com/v0/b/collect-staging-env.appspot.com/o/logos%2Fsuppliers-img12.jpg?alt=media&token=4ba77fc0-0260-4f5d-9309-58660b015bda',
        'https://firebasestorage.googleapis.com/v0/b/collect-staging-env.appspot.com/o/logos%2Fsuppliers-img13.jpg?alt=media&token=673d3578-cac8-48b5-abf4-710cf3433138',
        'https://firebasestorage.googleapis.com/v0/b/collect-staging-env.appspot.com/o/logos%2Fsuppliers-img14.jpg?alt=media&token=c476bfe1-bae4-4860-b0e0-ef02f46c533c',
        'https://firebasestorage.googleapis.com/v0/b/collect-staging-env.appspot.com/o/logos%2Fsuppliers-img15.jpg?alt=media&token=c6bf8a0b-b0cf-43b5-a280-45580d8ebdba',
      ],
    }
  },
  methods: {
  },
}
</script>
<style scoped>
.outside {
  width: 96%;
  margin: 15% auto;
  max-width: 1200px;
}
@media screen and (min-width:896px){
  .outside {
    width: 90%;
    margin: 7% auto;
  }
}
.loop_wrap {
  display: flex;
  /* width: 100vw; */
  height: 90px;
  overflow: hidden;
  margin: 0 auto;
}

.loop_wrap img {
  width: auto;
  height: 100%;
}
.imgSpace {
  margin: 1%;
  height: 40px;
}
@media screen and (min-width:896px){
  .imgSpace {
    margin: 1%;
    height: 80px;
  }
}
.title_ {
  font-size: 1.9rem;
  font-weight: 600;
  color: black;
}
@media screen and (min-width:896px){
  .title_ {
    font-size: 2.9rem;
    font-weight: 600;
    color: black;
  }
}
.subtitle_ {
  font-size: 0.8rem;
  font-weight: 600;
  /* color: black; */
  margin: 2% 0 2% 0;
}
@media screen and (min-width:896px){
  .subtitle_ {
    font-size: 1.1rem;
    font-weight: 600;
    /* color: black; */
    margin: 0 0 0% 0;
  }
}
</style>
