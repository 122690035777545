<template>
  <div class="space_ ">
    <b-card>
      <b-row>
        <b-col lg="6">
          <b-card-img
            src="https://firebasestorage.googleapis.com/v0/b/collect-staging-env.appspot.com/o/brand%2Fgir-green-top.png?alt=media&token=86537cbe-eab1-41e4-acdb-55fd196d255a"
            class="card-height"
          />
        </b-col>
        <b-col lg="6">
          <div class="titleSpace text-center">
            <div class="title_">
              転コレが
            </div>
            <div class="title_">
              あなたにとっての
            </div>
            <div class="title_">
              ガラスの靴。
            </div>
          </div>
          <div class="comment mt-3 text-center">
            やりたいお仕事が必ず見つかります！
          </div>
          <div class="comment mt-1 text-center">
            転コレの｢シンデレラフィット｣を
          </div>
          <div class="comment mt-1 text-center">
            ぜひお試しください！
          </div>
          <div class="mt-3">
            <b-card class="">
              <div class="youtube-space ">
                <youtube
                  ref="youtube"
                  video-id="c5ddox6UBuA"
                  fit-parent
                />
              </div>
            </b-card>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BCardImg, BRow, BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardImg,
    // BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
    }
  },
  mounted() {
    // this.$refs.youtube.player.playVideo()
  },
}
</script>
<style scoped>
@media screen and (min-width:896px){
  /*画面幅が781px以上の時*/
  .space_ {
    width: 80%;
    margin: 5% auto 0 auto;
  }
}
.card-height {
  height: 300px;
}
@media screen and (min-width:896px){
  /*画面幅が781px以上の時*/
  .card-height {
    height: 600px;
  }
}
.youtube-space {
  width: 300px;
  margin: 0 auto;
}
.title_ {
  font-size: 3.3rem;
  font-weight: 600;
}
.comment {
  font-size: 1.3rem;
  font-weight: 600;
}
.titleSpace {
  margin: 5% 0;
  padding: 0 0 0 5%;
}
</style>
