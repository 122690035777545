<template>
  <div class="space_ ">
    <b-card>
      <b-row>
        <b-col lg="6">
          <b-card-img
            src="https://firebasestorage.googleapis.com/v0/b/collect-staging-env.appspot.com/o/brand%2Ften-colle-girl-blue.png?alt=media&token=d33e1f28-7663-429f-911f-b1215a43e779"
            class=""
          />
        </b-col>
        <b-col lg="6">
          <div class="titleSpace text-center">
            <div class="title_">
              声にならない
            </div>
            <div class="title_">
              心の叫び、
            </div>
            <div class="title_ text-center">
              届きます！
            </div>
          </div>
          <div class="comment mt-1 text-center">
            言いにくい希望も空気も、くみ取ります！
          </div>
          <div class="comment text-center">
            転コレの転職なら
          </div>
          <div class="comment text-center">
            思わず感動で言葉を失います。
          </div>
          <div class="mt-3">
            <b-card class="">
              <div class="youtube-space ">
                <youtube
                  video-id="uoViRh1MQ8A"
                  fit-parent
                />
              </div>
            </b-card>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BCardImg, BRow, BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardImg,
    // BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
    }
  },
  mounted() {
    // this.$refs.youtube.player.playVideo()
  },
}
</script>
<style scoped>
@media screen and (min-width:896px){
  /*画面幅が781px以上の時*/
  .space_ {
    width: 80%;
    margin: 5% auto 0 auto;
  }
}
.card-height {
  height: 300px;
}
.youtube-space {
  margin: 0 auto;
}
.title_ {
  font-size: 2rem;
  font-weight: 600;
}
.comment {
  font-size: 1rem;
  font-weight: 600;
}
.titleSpace {
  margin: 5% 0;
  padding: 0 0 0 5%;
}
/* ---------------------------------------- */
blockquote {
  position: relative;
  /* padding: 30px 15px 8px 15px; */
  padding: 12% 5% 3% 3%;
  box-sizing: border-box;
  font-style: italic;
  background: #ffffff;
  color: #555;
}
blockquote:before{
  display: inline-block;
  position: absolute;
  top: 25%;
  /* top: 30px; */
  left: 1px;
  content: "“";
  font-family: sans-serif;
  color: #7492e2;
  font-size: 90px;
  line-height: 1;
}
blockquote:after{
  display: inline-block;
  position: absolute;
  top: 90%;
  /* top: 105px; */
  left: 250px;
  content: "”";
  font-family: sans-serif;
  color: #7492e2;
  font-size: 90px;
  line-height: 1;
}
blockquote p {
  padding: 0;
  margin: 10px 0;
  line-height: 1.7;
}
blockquote cite {
  display: block;
  text-align: right;
  color: #994242;
  font-size: 0.9em;
}
</style>
