<template>
  <div class="">
    <Girl2 />
    <Girl1 />
    <Girl3 />
  </div>
</template>

<script>
import Girl1 from '@/components/basic/top/components/Girl1.vue'
import Girl2 from '@/components/basic/top/components/Girl2.vue'
import Girl3 from '@/components/basic/top/components/Girl3.vue'

export default {
  components: {
    Girl1,
    Girl2,
    Girl3,
  },
  directives: {
  },
  data() {
    return {
    }
  },
  methods: {
    cancel() {
      //
    },
  },
}
</script>
