<template>
  <div>
    <header>
      <h1
        class="text-white mt-1"
      >
        <img
          :src="require('@/assets/images/logo/logo4.png')"
          height="35px"
        >
      </h1>
      <nav class="gnav mt-1 pc">
        <ul class="menu">
          <!-- <li>
            <a
              class="listText"
              @click="moveJobs"
            >
              求人情報
            </a>
          </li> -->
          <li>
            <a
              class="listText"
              @click="move('contact')"
            >
              お問い合わせ
            </a>
          </li>
          <li>
            <a
              class="listText"
              @click="move('login')"
            >
              会員ログイン
            </a>
          </li>
        </ul>
      </nav>
      <nav class="gnav mt-1 sm">
        <ul class="menu">
          <!-- <li>
            <a
              class="listText"
              @click="moveJobs"
            >
              <feather-icon
                icon="BookmarkIcon"
                size="17"
                class=""
              />
            </a>
          </li> -->
          <li>
            <a
              class="listText"
            >
              <feather-icon
                icon="HelpCircleIcon"
                size="17"
                class=""
                @click="move('contact')"
              />
            </a>
          </li>
          <li>
            <a
              class="listText"
            >
              <feather-icon
                icon="LogInIcon"
                size="17"
                class=""
                @click="move('login')"
              />
            </a>
          </li>
        </ul>
      </nav>
    </header>
    <TopMSGVideo class="pc" />
    <RegisterBtnPC class="pc" />
    <TopMSGVideoForSM class="sm" />
    <TopMSGSM class="sm" />
    <GirlMainPC class="pc" />
    <GirlMainSM class="sm" />
    <CompanyLogo />
    <!-- <Intro /> -->
    <Appeal />
    <AppealCard />
    <RegisterBtnSM class="sm" />
    <RegisterBtnPC class="pc" />
    <CaseCard />
    <RegisterBtnSM class="sm" />
    <RegisterBtnPC class="pc" />
    <WorkFlow />
    <RegisterBtnSM class="sm" />
    <RegisterBtnPC class="pc" />
    <Info />
    <Fotter />
  </div>
</template>

<script>
// import {
//   BRow,
//   // BCol,
// } from 'bootstrap-vue'
// import TopView from '@/components/basic/top/TopView.vue'
// import Intro from '@/components/basic/top/section/Intro.vue'
import Appeal from '@/components/basic/top/section/Appeal.vue'
import AppealCard from '@/components/basic/top/section/AppealCard.vue'
import CaseCard from '@/components/basic/top/section/CaseCard.vue'
// import RegisterBtn from '@/components/basic/top/section/RegisterBtn.vue'
import WorkFlow from '@/components/basic/top/section/WorkFlow.vue'
import Info from '@/components/basic/top/section/Info.vue'
import Fotter from '@/components/basic/top/section/Fotter.vue'
import CompanyLogo from '@/components/basic/top/section/CompanyLogo.vue'
import TopMSGVideo from '@/components/basic/top/TopMSGVideo.vue'
import TopMSGVideoForSM from '@/components/basic/top/TopMSGVideoForSM.vue'
import GirlMainPC from '@/components/basic/top/GirlMainPC.vue'
import GirlMainSM from '@/components/basic/top/GirlMainSM.vue'
import TopMSGSM from '@/components/basic/top/TopMSGSM.vue'
import RegisterBtnSM from '@/components/basic/top/components/RegisterBtnSM.vue'
import RegisterBtnPC from '@/components/basic/top/components/RegisterBtnPC.vue'

export default {
  components: {
    // BRow,
    // BCol,
    // TopView,
    TopMSGVideo,
    // Intro,
    Appeal,
    AppealCard,
    CaseCard,
    // RegisterBtn,
    WorkFlow,
    Info,
    Fotter,
    CompanyLogo,
    TopMSGVideoForSM,
    GirlMainPC,
    GirlMainSM,
    TopMSGSM,
    RegisterBtnSM,
    RegisterBtnPC,
  },
  data() {
    return {
    }
  },
  methods: {
    moveJobs() {
      this.$router.push({ name: 'jobs' })
    },
    move(val) {
      // window.console.log('memo', val)
      this.$router.push({ name: val })
    },
  },
}
</script>

<style scoped>
header {
  width: 100%; /* 幅いっぱいを指定 */
  height: 60px; /* 高さを50pxに指定 */
  background: rgb(44, 43, 43); /* 背景色にグレーを指定 */
  padding: 20px 50px; /* ヘッダーに上下左右それぞれ余白を指定 */
  box-sizing: border-box; /* padding分を含んで幅を100%にするため */
  position: fixed; /* ウィンドウを基準に画面に固定 */
  top: 0; /* 上下の固定位置を上から0pxにする */
  left: 0; /* 左右の固定位置を左から0pxにする */
  display: flex; /* 中の要素を横並びにする */
  align-items: center; /* 中の要素を上下中央に並べる */
  z-index: 9999; /* 最も上の要素にする */
}
header .gnav .menu {
  display: flex; /* 中の要素を横並びにする */
  color: white
}
a {
  text-decoration: none;
}
header .gnav .menu li {
  list-style: none; /* リストの[・]を消す */
}

header .gnav .menu li + li {
  margin-left: 40px; /* メニューそれぞれに間隔をあけるため */
}
.sm {
  display: block;
}
@media screen and (min-width:896px){
  .sm {
    display: none;
  }
}
.pc {
  display: none;
}
@media screen and (min-width:896px){
  .pc {
    display: block;
  }
}
.listText {
  color: white;
  font-size: 1.2rem;
}
</style>
