<template>
  <div
    style="background-color: rgb(39, 39, 39);height: 120px;"
    class="footerSpace text-right"
  >
    <div class="itemSpce">
      <span class="item">
        <a
          href="http://collect-agency.com/"
          style="color:rgb(221, 220, 220);"
        >
          運営会社
        </a>
      </span>
      <span class="item">
        <a
          style="color:rgb(221, 220, 220);"
          @click="move('privacy')"
        >
          個人情報の取り扱い
        </a>
      </span>
      <span class="item">
        <a
          style="color:rgb(221, 220, 220);"
          @click="move('term')"
        >
          利用規約
        </a>
      </span>
    </div>
    <div class="text-center mt-2">
      &copy;{{ new Date().getFullYear() }} 株式会社コレクト・エージェンシー
    </div>
  </div>
</template>

<script>

export default {
  components: {
  },
  data() {
    return {
    }
  },
  methods: {
    move(val) {
      this.$router.push({ name: val })
    },
  },
}
</script>

<style scoped>
.footerSpace_ {
  background-color: rgb(39, 39, 39) !important;
  color: rgb(156, 156, 156);
  height: 100px;
  width: 100%;
  display: inline;
}
.itemSpce {
  margin: 0% 5% 0 0%;
  padding: 6% 2% 0 0%;
}
@media screen and (min-width:896px){
  .itemSpce {
    margin: 0% 5% 0 0%;
    padding: 2% 2% 0 0%;
  }
}
.item {
  margin: 0% 0 0 2%;
  font-size: 1rem;
}
</style>
