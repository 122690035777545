<template>
  <div class="work-flow-card">
    <div class="work-flow-card-text">
      <div class="titleText mb-2">
        {{ workFlowName }}
      </div>
      <div
        v-for="(textItem, index) in workFlowTexts"
        :key="index"
        class="br-adjust itemText"
      >
        {{ textItem }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    workFlowName: {
      type: String,
      default: '',
      required: true,
    },
    workFlowTexts: {
      type: Array[String],
      default: [''],
      required: true,
    },
  },
}
</script>

<style scoped>
.work-flow-card {
  width: 90%;
  margin: 1.5em auto;
  padding: 1em 1em;
}
.work-flow-card-text {
  font-weight: 600;
}
.br-adjust {
  display: inline-block;
}
.titleText {
  color: white;
  font-size: 1.8rem;
}
.itemText {
  color: white;
  font-size: 1.2rem;
  line-height: 2;
}
</style>
