var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"contentD-wrapper mt-3"},[_c('div',{staticClass:"text-center work-flows-title"},[_vm._v(" 転職までの流れ ")]),_c('div',{staticClass:"contentD-box"},[_c('work-flow-card',{attrs:{"work-flow-name":"1. 転コレサイトに登録！","work-flow-texts":[
        '年齢性別問わず、転職したいなら今すぐ登録！',
        'ぴったりのお仕事が必ず見つかります！' ]}}),_c('work-flow-card',{attrs:{"work-flow-name":"2. 簡単な質問に回答","work-flow-texts":[
        'ヒアリングの前にあなたのことを教えて下さい。',
        '転コレエージェントがぴったりのお仕事をご提案させて頂きます。',
        '登録情報が多いほど、求人案件がマッチングしやすくなりますよ！'
      ]}}),_c('work-flow-card',{attrs:{"work-flow-name":"3. ヒアリング","work-flow-texts":[
        '転コレエージェントとのヒアリングです。',
        'あなたの希望を遠慮せず、たくさん教えて下さい！',
        'ご希望の日時にヒアリングを行います。'
      ]}}),_c('work-flow-card',{attrs:{"work-flow-name":"4. クライアント企業様とご面談","work-flow-texts":[
        'ご希望の会社が決まったら面接の準備をしましょう！面接は誰でも不安ですが、ご心配なく！転コレエージェントがしっかりとフォローします！',
        'あなたの良さを最大限アピールしましょう。'
      ]}}),_c('work-flow-card',{attrs:{"work-flow-name":"5. 結果のご連絡","work-flow-texts":[
        '面接の結果をお伝えします。ご希望の会社から採用されると、新しい生活が始まります！',
        '万が一、その会社とご縁が無くても安心してください。魅力的な会社はたくさんあります。また一緒に探して面接に行きましょう！'
      ]}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }