<template>
  <div class="">
    <RegisterBtnSM />
    <Girl2SM />
    <Girl1SM />
    <Girl3SM />
    <RegisterBtnSM />
  </div>
</template>

<script>
import Girl1SM from '@/components/basic/top/components/Girl1SM.vue'
import Girl2SM from '@/components/basic/top/components/Girl2SM.vue'
import Girl3SM from '@/components/basic/top/components/Girl3SM.vue'
import RegisterBtnSM from '@/components/basic/top/components/RegisterBtnSM.vue'

export default {
  components: {
    Girl1SM,
    Girl2SM,
    Girl3SM,
    RegisterBtnSM,
  },
  directives: {
  },
}
</script>
