<template>
  <div class="outside">
    <div class="appealSpace">
      <div class="mt-4 text-center top-text-title-explain">
        <span>既に沢山の方が転職に成功しています！</span>
      </div>
      <div class="text-center caseTitle">
        転コレ成功事例
      </div>
      <div class="mb-3 text-center top-text-title-explain">
        <span>転コレ始めませんか？</span>
      </div>
      <b-row>
        <b-col lg="4">
          <b-card
            title=""
            img-src="@/assets/images/brand/case3.png"
            img-height="200px"
            class="text-center"
          >
            <h2 class="cardTitle">
              育休からの社会復帰で<br>営業事務へ
              <!-- 育休からの社会復帰で<br class="display-mobile">営業事務へ -->
            </h2>
            <b-card-text>
              私が以前、勤めていた企業は育休制度が完備されておらず、退職せざるを得ない状況でした。育休の間に落ち着いたら次の職場を求めていた時に、女性の転職に強い転コレと出会い、エージェントの方に希望を伝えると、育休制度など充実し、女性が輝ける職場をご紹介していただき、営業事務として社会復帰することできました。同じ理由で働く場を失う機会は少なからずあると思うので大変感謝しております。
            </b-card-text>
            <b-alert
              variant="secondary"
              show
            >
              <div class="alert-body mt-1">
                <div class="d-flex">
                  <div class="mr-1">
                    <b-avatar
                      size="35"
                      src="https://firebasestorage.googleapis.com/v0/b/collect-staging-env.appspot.com/o/brand%2Fgirl-green.png?alt=media&token=decd9d77-1d03-4797-b794-a03bf691d4e5"
                      variant="light-primary"
                    />
                  </div>
                  <div class="text-left">
                    親身で手厚いフォロー、、姐さん達とは大違い、、
                  </div>
                </div>
              </div>
            </b-alert>
          </b-card>
        </b-col>
        <b-col lg="4">
          <b-card
            title=""
            img-src="@/assets/images/brand/case2.png"
            img-height="200px"
            class="text-center"
          >
            <h2 class="cardTitle">
              アパレル業から<br>上場企業営業職へ
            </h2>
            <b-card-text>
              私は大学卒業後、職を点々とし当時、アパレル業でアルバイトをしていました。正直、待遇面、勤務時間等に不満がありましたが、人と接することは好きだったので、何となく日々を過ごしていた感じでした。今回、転コレという存在を知り、エージェントの方とヒアリングを重ね、たくさんの人に物の魅力を伝える営業職へ挑戦を決意しました。上場企業への営業職へ転身が成功しました。転コレで本当に良かったと思います。
            </b-card-text>
            <b-alert
              variant="secondary"
              show
            >
              <div class="alert-body mt-1">
                <div class="d-flex">
                  <div class="mr-1">
                    <b-avatar
                      size="35"
                      src="https://firebasestorage.googleapis.com/v0/b/collect-staging-env.appspot.com/o/brand%2Fgril-red.png?alt=media&token=4033ef9a-cd19-48e6-baf6-85065c0a3c96"
                      variant="light-primary"
                    />
                  </div>
                  <div class="text-left">
                    そんな温かい場所で営業できるなんておとぎ話みたい、、
                  </div>
                </div>
              </div>
            </b-alert>
          </b-card>
        </b-col>
        <b-col lg="4">
          <b-card
            title=""
            img-src="@/assets/images/brand/case1.png"
            img-height="200px"
            class="text-center"
          >
            <h2 class="cardTitle">
              ナイトワークから<br>上場企業役員秘書へ
            </h2>
            <b-card-text>
              私は、東京都内でナイトワークに従事していました。ですが、コロナの影響で休業が増え、すがる思いで転職を求めていた時に、女性の転職に強い転コレに出会いました。ヒアリングをしていただき何の取り柄もない私が感動したのは、｢ナイトワーク経験のある方は気配り、目配り、心配りに長けている。秘書という仕事はそれが一番必要です。｣という言葉でした。おかげ様で今は役員秘書として新たなスタートを切りました。
            </b-card-text>
            <b-alert
              variant="secondary"
              show
            >
              <div class="alert-body mt-1">
                <div class="d-flex">
                  <div class="mr-1">
                    <b-avatar
                      size="35"
                      src="https://firebasestorage.googleapis.com/v0/b/collect-staging-env.appspot.com/o/brand%2Fgirl-bule.png?alt=media&token=a579655b-d85a-437a-93ce-034100fcb34e"
                      variant="light-primary"
                    />
                  </div>
                  <div class="text-left">
                    転コレもライフセーバーだったのね、、
                  </div>
                </div>
              </div>
            </b-alert>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BRow, BCol, BCard, BCardText, BAlert, BAvatar,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BAlert,
    BAvatar,
  },
  directives: {
    Ripple,
  },
  props: {
  },
  data() {
    return {
    }
  },
  mounted() {
  },
  methods: {
  },
}
</script>

<style scoped>
.outside {
  /* padding: 1.5em 3%; */
  background-color:rgba(228,228,242,0.3);
  margin: 5% 0 0 0;
  padding: 2% 0 2% 0;
}
.appealSpace {
  width: 85%;
  margin: 0 auto;
}
@media screen and (min-width:896px){
  /*画面幅が781px以上の時*/
  .appealSpace {
    width: 80%;
    margin: 0 auto 5% auto;
  }
}
.cardTitle {
  font-weight: 600;
  color: rgb(39, 39, 39);
}
.caseTitle {
  font-size: 1.8em;
  font-weight: 600;
  color: black;
  margin: 0 auto;
}
@media screen and (min-width:896px){
  /*画面幅が781px以上の時*/
  .caseTitle {
    font-size: 2.2em;
    margin: 0% 0;
  }
}
</style>
