<template>
  <div class="title-space">
    <div class="text-center">
      <small>大人気CM絶賛放映中！！</small>
    </div>
    <div class="title-text text-center">
      転職するなら
    </div>
    <div class="title-text text-center">
      ｢<span class="tencolle-text">転コレ</span>あります！｣
    </div>
    <div class="text-center">
      <small>転コレならぴったりのお仕事が見つかります！</small>
    </div>
  </div>
</template>

<script>

export default {
  components: {
  },
  directives: {
  },
  data() {
    return {
    }
  },
  methods: {
    cancel() {
      //
    },
  },
}
</script>

<style scoped>
.title-text {
  font-size: 1.4rem;
  font-weight: 600;
  color: rgb(78, 78, 78);
}
.title-space {
  margin: -10% 0 5% 0;
}
.tencolle-text {
  color: rgb(202, 16, 16);
}
</style>
