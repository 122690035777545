<template>
  <div class="outside">
    <div class="space1" />
    <div class="text-center contentTitle">
      <span class="br-adjust">転コレなら</span>
      <span class="br-adjust">ぴったりのお仕事が</span>
      <br>
      <span class="br-adjust">見つかるまで</span>
      <span class="br-adjust">徹底フォロー！</span>
    </div>
    <div class="space1" />
    <!-- <div class="space1"></div>
      <div class="itemSpace">
        <v-layout wrap>
          <StrengthItem
            :title='["上場企業で", "働ける"]'
            :imageUrl="StrengthImage01"
            description="転コレは上場企業との接点が他社より深く、数多くの案件を抱えております。それは弊社が上場企業より信頼をいただいている証拠であり、1つ上のステージの転職をご案内できます。"
          />
          <StrengthItem
            :title='["女性の", "転職にも", "強い"]'
            :imageUrl="StrengthImage02"
            description="産休、育休、職場環境など女性が働いていく上で、必ずピックアップされるデリケートな問題です。弊社は貴女が輝ける企業様を多数ご紹介できます。"
          />
          <StrengthItem
            :title='["未経験の", "仕事に", "強い"]'
            :imageUrl="strengthImage03"
            description="新しい仕事に挑戦するというのは大変勇気がいる行動です。転コレはそんなあなたの挑戦を全面バックアップし、未経験の案件でもよりよいマッチングを実現します。"
          />
        </v-layout>
      </div> -->
  </div>
</template>

<script>
export default {
  components: {
  },
  data() {
    return {
    }
  },
  methods: {
  },
}
</script>
<style scoped>
.outside {
  margin: 5% auto;
  width: 90%;
  max-width: 1200px;
}
.space1 {
  margin: 5% auto;
  width: 90%;
  border-color: #770000;
  border-style: solid;
  border-width: 2px;
}
@media screen and (min-width:896px){
  /*画面幅が781px以上の時*/
  .space1 {
    margin: 0% auto;
    width: 50%;
  }
}
.contentTitle {
  font-weight: 600;
  line-height: 1.4;
  color: black;
  width: 90%;
  max-width: 480px;
  /* padding: 1em; */
  margin: 0 auto;
  /* border-top: solid 2px #770000;
  border-bottom: solid 2px #770000; */
  font-size: 1.4em;
}
@media screen and (min-width:896px){
  /*画面幅が781px以上の時*/
  .contentTitle {
    font-weight: 600;
    line-height: 1.4;
    color: black;
    width: 90%;
    max-width: 480px;
    /* padding: 1em; */
    margin: 0 auto;
    /* border-top: solid 2px #770000;
    border-bottom: solid 2px #770000; */
    font-size: 2em;
  }
}
.itemSpace {
  margin: 3% 0;
}
</style>
