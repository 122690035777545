<template>
  <div class="wrap">
    <div class="video-player-wrap">
      <video
        src="https://firebasestorage.googleapis.com/v0/b/collect-staging-env.appspot.com/o/brand%2F15608855410372.mov?alt=media&token=b72a7a08-c61b-487e-bfcc-82dca761207e"
        autoplay
        loop
        muted
        playsinline
        poster="https://firebasestorage.googleapis.com/v0/b/collect-staging-env.appspot.com/o/brand%2FF4CA6EAD-2CD1-4585-9D27-AC0F9B844B95.jpeg?alt=media&token=c119cbeb-5da3-4064-97da-8fe249550c04"
        class="video-player"
      />
    </div>
    <div class="text-center text-font-size-lg mt-4">
      <span>大人気CM絶賛放映中！！</span>
    </div>
    <div class="title-text text-center">
      転職するなら
      <!-- </div>
    <div class="title-text text-center"> -->
      ｢転コレあります！｣
    </div>
    <div class="text-center text-font-size-lg">
      転コレならぴったりのお仕事が見つかります！
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import 'video.js/dist/video-js.css'
/* eslint-enable */

export default {
  components: {},
  directives: {},
  data() {
    return {
    }
  },
  mounted() {
  },
  methods: {},
}
</script>

<style scoped>
.wrap {
  position: relative;
  top: -40px;
}
.video-player-wrap {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 105vh;
}
.video-player {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.overlay::after {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  content: "";
  background: rgba(0, 0, 0, 0.4);
}
.title-text {
  font-size: 2.3rem;
  font-weight: 600;
  color: rgb(78, 78, 78);
}
.title-text {
  font-size: 2.3rem;
  font-weight: 600;
  color: rgb(78, 78, 78);
}
</style>
