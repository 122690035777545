<template>
  <div class="appealSpace">
    <b-row>
      <b-col lg="4">
        <b-card
          title=""
          img-src="@/assets/images/brand/woman-office.jpeg"
          img-height="200px"
          class="text-center"
        >
          <div class="card-title-space">
            <div class="d-flexs mb-1 ">
              <b-avatar
                size="35"
                src="https://firebasestorage.googleapis.com/v0/b/collect-staging-env.appspot.com/o/brand%2Fgirl-green.png?alt=media&token=decd9d77-1d03-4797-b794-a03bf691d4e5"
                variant="light-primary"
              />
              <div class="cardTitle ml-50 mt-50">
                女性の転職にも強い
              </div>
            </div>
          </div>
          <b-card-text>
            産休、育休、職場環境など女性が働いていく上で、必ずピックアップされるデリケートな問題です。弊社は貴女が輝ける企業様を多数ご紹介できます。
          </b-card-text>
        </b-card>
      </b-col>
      <b-col lg="4">
        <b-card
          title=""
          img-src="@/assets/images/brand/strengthImage03.jpg"
          img-height="200px"
          class="text-center"
        >
          <div class="card-title-space">
            <div class="d-flexs mb-1 ">
              <b-avatar
                size="35"
                src="https://firebasestorage.googleapis.com/v0/b/collect-staging-env.appspot.com/o/brand%2Fgril-red.png?alt=media&token=4033ef9a-cd19-48e6-baf6-85065c0a3c96"
                variant="light-primary"
              />
              <div class="cardTitle ml-50 mt-50">
                未経験の仕事にも強い
              </div>
            </div>
          </div>
          <b-card-text>
            新しい仕事に挑戦するというのは大変勇気がいる行動です。転コレはそんなあなたの挑戦を全面バックアップし、未経験の案件でもよりよいマッチングを実現します。
          </b-card-text>
        </b-card>
      </b-col>
      <b-col lg="4">
        <b-card
          title=""
          img-src="@/assets/images/brand/building2.jpg"
          img-height="200px"
          class="text-center"
        >
          <div class="card-title-space">
            <div class="d-flexs mb-1 ">
              <b-avatar
                size="35"
                src="https://firebasestorage.googleapis.com/v0/b/collect-staging-env.appspot.com/o/brand%2Fgirl-bule.png?alt=media&token=a579655b-d85a-437a-93ce-034100fcb34e"
                variant="light-primary"
              />
              <div class="cardTitle ml-50 mt-50">
                上場企業で働ける
              </div>
            </div>
          </div>
          <b-card-text>
            転コレは上場企業との接点が他社より深く、数多くの案件を抱えております。それは弊社が上場企業より信頼をいただいている証拠であり、1つ上のステージの転職をご案内できます。
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BRow, BCol, BCard, BCardText, BAvatar,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BAvatar,
  },
  directives: {
    Ripple,
  },
  props: {
  },
  data() {
    return {
    }
  },
  mounted() {
  },
  methods: {
  },
}
</script>

<style scoped>
.appealSpace {
  width: 85%;
  margin: 0 auto;
}
@media screen and (min-width:896px){
  /*画面幅が781px以上の時*/
  .appealSpace {
    width: 70%;
    margin: 0 auto;
  }
}
.cardTitle {
  font-size: 1.2rem;
  font-weight: 600;
  color: rgb(39, 39, 39);
}
@media screen and (min-width:896px){
  /*画面幅が781px以上の時*/
  .cardTitle {
    font-weight: 600;
    color: rgb(39, 39, 39);
  }
}
.card-title-space {
  width:99%;
  /* margin: 0 0 0 14%; */
  margin: 0 auto;
}
</style>
