<template>
  <div class="btn-space">
    <div class="text-center btn_title">
      メールやTwitterで簡単に登録！
    </div>
    <b-button
      variant="primary"
      class=""
      block
      size="lg"
      @click="move"
    >
      <feather-icon
        icon="MailIcon"
        size="20"
        class="mr-50"
      />
      <span class="mr-50">
        登録する(無料)
      </span>
      <feather-icon
        icon="TwitterIcon"
        size="20"
        class="mr-50"
      />
    </b-button>
    <div class="text-center btn_title">
      ｢てんてんコレコレ、転コレてん♫｣で始めよう！
    </div>
  </div>
</template>

<script>
import {
  BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BButton,
  },
  directives: {
  },
  methods: {
    move() {
      this.$router.push({ name: 'sendmail' })
    },
  },
}
</script>

<style scoped>
.btn-space {
  width: 90%;
  margin: 7% auto;
}
.btn_title {
  color:rgb(146, 146, 146);
  font-size: 0.9rem;
  font-weight: 600;
}
@media screen and (min-width:896px){
  .btn_title {
    color:rgb(146, 146, 146);
    font-size: 1rem;
    font-weight: 600;
  }
}
</style>

<style scoped>
.btn-primary {
  background: radial-gradient( #991b1b, #6d0505);
  color: radial-gradient( #991b1b, #6d0505);
  outline-color: radial-gradient( #ec0303, #270101);
}
.btn-primary:hover, .btn-primary:focus,
.btn-primary:active, .btn-primary:active:focus, .btn-primary:active:hover, .btn-primary:active.focus,
.btn-primary.active, .btn-primary.active:focus, .btn-primary.active:hover, .btn-primary.active.focus,
.open > .dropdown-toggle.btn-primary,
.open > .dropdown-toggle.btn-primary:hover,
.open > .dropdown-toggle.btn-primary:focus,
.open > .dropdown-toggle.btn-primary.focus,
.btn-primary.disabled:hover, .btn-primary[disabled]:hover, fieldset[disabled] .btn-primary:hover,
.btn-primary.disabled:focus, .btn-primary[disabled]:focus, fieldset[disabled] .btn-primary:focus,
.btn-primary.disabled.focus, .btn-primary[disabled].focus, fieldset[disabled] .btn-primary.focus {
  background: radial-gradient( #991b1b, #6d0505);
  color: radial-gradient( #991b1b, #6d0505);
  outline-color: radial-gradient( #ec0303, #270101);
}
</style>
