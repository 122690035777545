<template>
  <div class="contentA-wrappers">
    <div class="contentA-box">
      <div class="text-center mt-12">
        <!-- <div class="subtitle">ご相談はお気軽に</div> -->
        <div class="subtitle">
          フリーダイヤル
        </div>
        <h2 class="item">
          <a
            href="tel:0120393930"
            class="text-white"
          >
            0120-39-3930
          </a>
        </h2>
        <div class="subtitle">
          失敗しない転職なら｢転コレ｣
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
    }
  },
  methods: {
  },
}
</script>

<style scoped>
.contentA-wrappers {
  background-image: url('../../../../assets/images/brand/thought-catalog-UK78i6vK3sc-unsplash.jpg');
  background-size: cover;
  background-position: 40% 20%;
  /* background-color:rgba(98, 98, 102, 0.8); */
  background-color: rgba(46, 46, 46, 0.1);
  /* background-blend-mode:lighten; */
  margin: 0;
  padding: 10% 0;
  color: white;
  width: 100%;
}
.contentA-box {
  /* width: 90%; */
  /* max-width: 1200px; */
  margin: 0 auto;
}
.contentA-title {
  font-weight: 600;
  line-height: 1.8;
  color: white;
  padding: 1em;
  margin: 1em auto;
  width: 90%;
  max-width: 480px;
  border-top: solid 2px #770000;
  border-bottom: solid 2px #770000;
}
.contentA-title.smAndUp {
  font-size: 1.5em;
}
.contentA-title.xsOnly {
  font-size: 1.2em;
}
.item {
  margin: 0 1em 0 1em;
  font-weight: 600;
  line-height: 2;
  color: white;
  /* font-size: 1.4rem; */
}
@media screen and (min-width:896px){
  /*画面幅が781px以上の時*/
  .item {
    font-weight: 600;
    line-height: 2;
    font-size: 2.4rem;
  }
}
.subtitle {
  /* margin: -14%; */
  font-weight: 400;
  line-height: 2;
  font-size: 0.8rem;
}
@media screen and (min-width:896px){
  /*画面幅が781px以上の時*/
  .subtitle {
    /* font-weight: 600; */
    /* line-height: 2; */
    font-size: 1rem;
  }
}
.br-adjust {
  display: inline-block;
}
</style>
