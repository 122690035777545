<template>
  <section class="contentD-wrapper mt-3">
    <div class="text-center work-flows-title">
      転職までの流れ
    </div>
    <div class="contentD-box">
      <work-flow-card
        work-flow-name="1. 転コレサイトに登録！"
        :work-flow-texts="[
          '年齢性別問わず、転職したいなら今すぐ登録！',
          'ぴったりのお仕事が必ず見つかります！',
        ]"
      />
      <work-flow-card
        work-flow-name="2. 簡単な質問に回答"
        :work-flow-texts="[
          'ヒアリングの前にあなたのことを教えて下さい。',
          '転コレエージェントがぴったりのお仕事をご提案させて頂きます。',
          '登録情報が多いほど、求人案件がマッチングしやすくなりますよ！'
        ]"
      />
      <work-flow-card
        work-flow-name="3. ヒアリング"
        :work-flow-texts="[
          '転コレエージェントとのヒアリングです。',
          'あなたの希望を遠慮せず、たくさん教えて下さい！',
          'ご希望の日時にヒアリングを行います。'
        ]"
      />
      <!-- <work-flow-card
        work-flow-name="4. 書類選考"
        :work-flow-texts="[
          '書類選考の後、クライアント面談です。',
          'ご記入いただいたスキルシートを元に、クライアント様が書類選考を行います。'
        ]"
      /> -->
      <work-flow-card
        work-flow-name="4. クライアント企業様とご面談"
        :work-flow-texts="[
          'ご希望の会社が決まったら面接の準備をしましょう！面接は誰でも不安ですが、ご心配なく！転コレエージェントがしっかりとフォローします！',
          'あなたの良さを最大限アピールしましょう。'
        ]"
      />
      <work-flow-card
        work-flow-name="5. 結果のご連絡"
        :work-flow-texts="[
          '面接の結果をお伝えします。ご希望の会社から採用されると、新しい生活が始まります！',
          '万が一、その会社とご縁が無くても安心してください。魅力的な会社はたくさんあります。また一緒に探して面接に行きましょう！'
        ]"
      />
    </div>
  </section>
</template>

<script>
import WorkFlowCard from '@/components/basic/top/section/WorkFlowCard.vue'

export default {
  components: {
    WorkFlowCard,
  },
  data() {
    return {
    }
  },
}
</script>

<style scoped>
.contentD-wrapper {
  padding: 2em 0;
  /* background: linear-gradient(-248deg, #9e0000, #770000) ; */
  background: radial-gradient( #bd0404, #270101) ;
  width: 100%;
}
.contentD-box {
  width: 100%;
  /* max-width: 1080px; */
  margin: 0 auto;
}
@media screen and (min-width:896px){
  /*画面幅が781px以上の時*/
  .contentD-box {
    width: 45%;
  }
}
.work-flows-title {
  font-size: 1.8em;
  font-weight: 600;
  color: white;
  margin: 5% 0 0 0;
}
@media screen and (min-width:896px){
  /*画面幅が781px以上の時*/
  .work-flows-title {
    font-size: 3em;
  }
}
</style>
